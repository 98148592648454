<template>
  <BlocSimple class="d-flex flex-column h-100">
    <Header>
      <div v-if="affaire.affaire_activite_id && affaire.affaire_activite_id == 1">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/1.png)',
            width: '7rem',
            height: '7rem',
          }"
        ></div>
      </div>
      <div v-if="affaire.affaire_activite_id && affaire.affaire_activite_id == 2">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/2.svg)',
            width: '7rem',
            height: '7rem',
          }"
        ></div>
      </div>
      <div v-if="affaire.affaire_activite_id && affaire.affaire_activite_id == 3">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/3.svg)',
            width: '7rem',
            height: '7rem',
          }"
        ></div>
      </div>
      <div v-if="affaire.affaire_activite_id && affaire.affaire_activite_id == 4">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/4.svg)',
            width: '7rem',
            height: '7rem',
          }"
        ></div>
      </div>
      <div v-if="data.affaire_activite_id && data.affaire_activite_id == 5">
        <div
          class="avatar mr-3 border-0"
          v-bind:style="{
            backgroundImage: 'url(/affaires/4.svg)',
            width: '7rem',
            height: '7rem',
          }"
        ></div>
      </div>
      <div>
        <h1>{{ affaire.affaire_identifiant }}</h1>
        <div>{{ affaire.affaire_libelle }}</div>
        <UserViewer
          :userId="affaire.affaire_pilote_id"
          v-if="affaire.affaire_pilote_id"
          :show-text="true"
          class="mt-2"
        ></UserViewer>
      </div>
      <close-link
        class="ml-auto mr-3"
        text="Retour au tableau de bord"
        link="/crm/dashboard"
        v-if="!loadingData && !loadingComptes"
      ></close-link>
    </Header>
    <div class="d-flex flex-column flex-md-row h-100">
      <menu-left style="min-width: 140px">
        <menu-left-item route="crm_affaire_infos" text="Informations" icon="info"></menu-left-item>
        <menu-left-item route="crm_affaire_cdc" text="Spécifications" icon="compass"></menu-left-item>
        <menu-left-item route="crm_affaire_prepa" text="Préparation" icon="clipboard"></menu-left-item>
        <menu-left-item route="crm_affaire_hse" text="Risques" icon="alert-triangle"></menu-left-item>
        <!-- <menu-left-item route="crm_affaire_planning" text="Planning" icon="calendar"></menu-left-item>
        <menu-left-item route="crm_affaire_chiffrage" text="Chiffrage" icon="dollar-sign"></menu-left-item> -->
        <menu-left-item route="crm_affaire_offre" text="Offre PTC" icon="file-text"></menu-left-item>
        <menu-left-item route="crm_affaire_commande" text="Commande" icon="pen-tool"></menu-left-item>
        <menu-left-item route="crm_affaire_suivi" text="Suivi" icon="bookmark"></menu-left-item>
        <menu-left-item route="crm_affaire_dosi" text="Dosimétrie" icon="shield"></menu-left-item>
        <!-- <menu-left-item route="crm_affaire_prod" text="Production" icon="bar-chart-2"></menu-left-item> -->
        <menu-left-item route="crm_affaire_rex" text="REX" icon="book-open"></menu-left-item>
        <menu-left-item route="crm_affaire_satisfaction" text="Satisfaction" icon="star"></menu-left-item>
        <menu-left-item route="crm_affaire_users" text="Utilisateurs" icon="eye"></menu-left-item>
      </menu-left>
      <router-view
        class="pl-2 pr-2"
        :data="data"
        v-if="!loadingData && !loadingComptes && affaire && Object.keys(affaire).length > 0"
      ></router-view>
      <div>
        <div class="d-flex align-items-center" v-if="loadingData || loadingComptes">
          <loader class="m-5"></loader>
          <h2 class="m-0">Chargement...</h2>
        </div>
      </div>
      <div v-if="!Object.keys(affaire).length > 0 && !loadingComptes && !loadingData" class="p-3">
        <h2>Aucune donnée</h2>
      </div>
    </div>
  </BlocSimple>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import Header from "@/components/bases/Header.vue";
import MenuLeft from "@/components/bases/MenuLeft.vue";
import MenuLeftItem from "@/components/bases/MenuLeftItem.vue";
import Loader from "@/components/bases/Loader.vue";
import BlocSimple from "@/components/containers/ContainerBloc.vue";
import CloseLink from "@/components/bases/CloseLink.vue";
import UserViewer from "@/components/application/UserViewer.vue";

export default {
  components: {
    Header,
    MenuLeft,
    MenuLeftItem,
    Loader,
    BlocSimple,
    CloseLink,
    UserViewer,
  },
  props: {
    id: {
      Type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingData: false,
      loadingComptes: false,
    };
  },
  methods: {
    ...mapActions({
      getAffaire: "affaires/getAffaire",
      getData: "affaires/getData",
      loadChiffrages: "chiffrages/load",
      loadDosimetrie: "dosimetrie/getDoses",
      loadProductions: "productions/getProductions",
    }),
  },
  computed: {
    ...mapGetters({
      affaire: "affaires/affaire",
      data: "affaires/data",
    }),
    ...mapFields("affaires", [
      "affaire.affaire_phases",
      "affaire.affaire_dosimetrie",
      "affaire.affaire_edp",
      "affaire.affaire_production",
    ]),
  },
  watch: {
    "$route.params.id": function () {
      this.loadingComptes = true;
      this.getAffaire(this.id)
        .then(() => {
          this.loadChiffrages(this.affaire_phases);
          this.loadDosimetrie({
            doses: this.affaire_dosimetrie,
            edps: this.affaire_edp,
          });
          this.loadProductions(this.affaire_production);
          this.loadingComptes = false;
        })
        .finally(() => {
          this.loadingComptes = false;
          this.loadingData = false;
        });
    },
  },
  mounted() {
    this.loadingData = true;
    this.loadingComptes = true;
    this.getAffaire(this.id)
      .then(() => {
        this.loadChiffrages(this.affaire_phases);
        this.loadDosimetrie({
          doses: this.affaire_dosimetrie,
          edps: this.affaire_edp,
        });
        this.loadProductions(this.affaire_production);
        this.loadingComptes = false;
        this.loadingData = false;
      })
      .finally(() => {
        this.loadingComptes = false;
        this.loadingData = false;
      });
  },
};
</script>
